import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { ROUTES } from '../../../configs/routers';

interface Props extends RouteProps {
  auth: boolean;
}

const ProtectedRoute: React.SFC<Props> = props => {
  const { auth, ...restProps } = props;
  const state = props.location && props.location.state;
  if (auth) {
    return <Route {...restProps} />;
  }

  return (
    <Redirect
      to={{
        state,
        pathname: `${ROUTES.login}`,
      }}
    />
  );
};

export default ProtectedRoute;
