import { DEV } from '../constants';

enum APIService {
  woodFish,
  configPanel,
}

function getBaseUrl(service: APIService) {
  const API_URL = DEV
    ? 'http://127.0.0.1:8001/api'
    : 'https://apivn.woodfish.io/api';

  if (service === APIService.woodFish) {
    return API_URL;
  }

  if (service === APIService.configPanel) {
    return `${API_URL}/portal`;
  }

  return API_URL;
}

export const API_PATHS = {
  login: `${getBaseUrl(APIService.woodFish)}/login`,
  license: `${getBaseUrl(APIService.woodFish)}/license`,
  copyConfig: `${getBaseUrl(APIService.woodFish)}/copyConfig`,
  profile: `${getBaseUrl(APIService.woodFish)}/profile`,
  subscription: `${getBaseUrl(APIService.woodFish)}/subscription`,
  getWebConfig: `${getBaseUrl(APIService.woodFish)}/getWebConfig`,

  fetchQuestDaily: `${getBaseUrl(APIService.configPanel)}/fetchQuestDaily`,
  questDaily: `${getBaseUrl(APIService.configPanel)}/questDaily`,

  fetchGiftCode: `${getBaseUrl(APIService.configPanel)}/fetchGiftCode`,
  giftCode: `${getBaseUrl(APIService.configPanel)}/giftCode`,
  giftCodeReward: `${getBaseUrl(APIService.configPanel)}/giftCodeReward`,
  fetchReward: `${getBaseUrl(APIService.configPanel)}/fetchReward`,
  reward: `${getBaseUrl(APIService.configPanel)}/reward`,
  fetchAccumulation: `${getBaseUrl(APIService.configPanel)}/fetchAccumulation`,
  accumulation: `${getBaseUrl(APIService.configPanel)}/accumulation`,
  fetchTopDaily: `${getBaseUrl(APIService.configPanel)}/fetchTopDaily`,
  topDaily: `${getBaseUrl(APIService.configPanel)}/topDaily`,

  fetchWheelSpin: `${getBaseUrl(APIService.configPanel)}/fetchWheelSpin`,
  wheelSpin: `${getBaseUrl(APIService.configPanel)}/wheelSpin`,

  getCharacterConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/getCharacterConfig`,
  updateOrCreateCharacterConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/updateOrCreateCharacterConfig`,

  fetchResetLimit: `${getBaseUrl(APIService.configPanel)}/fetchResetLimit`,
  updateResetLimit: `${getBaseUrl(APIService.configPanel)}/updateResetLimit`,

  fetchSms: `${getBaseUrl(APIService.configPanel)}/fetchSms`,
  updateOrCreateSms: `${getBaseUrl(APIService.configPanel)}/updateOrCreateSms`,

  fetchRankingConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/fetchRankingConfig`,
  updateOrCreateRankingConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/updateOrCreateRankingConfig`,

  fetchWithdrawMoney: `${getBaseUrl(
    APIService.configPanel,
  )}/fetchWithdrawMoney`,
  updateOrCreateWithdrawMoney: `${getBaseUrl(
    APIService.configPanel,
  )}/updateOrCreateWithdrawMoney`,

  fetchUpReset: `${getBaseUrl(APIService.configPanel)}/fetchUpReset`,
  updateOrCreateUpReset: `${getBaseUrl(
    APIService.configPanel,
  )}/updateOrCreateUpReset`,

  fetchResetConfig: `${getBaseUrl(APIService.configPanel)}/fetchResetConfig`,
  resetConfig: `${getBaseUrl(APIService.configPanel)}/resetConfig`,

  fetchRankTitle: `${getBaseUrl(APIService.configPanel)}/fetchRankTitle`,
  ranktitleConfig: `${getBaseUrl(APIService.configPanel)}/ranktitleConfig`,

  fetchPostLogData: `${getBaseUrl(APIService.configPanel)}/fetchPostLogData`,
  postLogData: `${getBaseUrl(APIService.configPanel)}/postLogData`,

  updateWebConfig: `${getBaseUrl(APIService.configPanel)}/updateWebConfig`,

  fetchRegisterConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/fetchRegisterConfig`,
  updateRegisterConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/updateRegisterConfig`,

  fetchFeatureConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/fetchFeatureConfig`,
  updateFeatureConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/updateFeatureConfig`,

  fetchEventTime: `${getBaseUrl(APIService.configPanel)}/fetchEventTime`,
  eventTime: `${getBaseUrl(APIService.configPanel)}/eventTime`,

  fetchEventInGame: `${getBaseUrl(APIService.configPanel)}/fetchEventInGame`,
  eventInGameTime: `${getBaseUrl(APIService.configPanel)}/eventInGameTime`,

  getBanksVietQR: `${getBaseUrl(APIService.configPanel)}/getBanksVietQR`,

  fetchPaymentConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/fetchPaymentConfig`,

  updatePaymentConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/updatePaymentConfig`,

  fetchSwitchServerConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/fetchSwitchServerConfig`,
  updateSwitchServerConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/updateSwitchServerConfig`,

  fetchGoblinChaosConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/fetchGoblinChaosConfig`,
  updateGoblinChaosConfig: `${getBaseUrl(
    APIService.configPanel,
  )}/updateGoblinChaosConfig`,

  getBlogKey: `${getBaseUrl(APIService.configPanel)}/getBlogKey`,
  createUserBlog: `${getBaseUrl(APIService.configPanel)}/createUserBlog`,
  updateUserBlog: `${getBaseUrl(APIService.configPanel)}/updateUserBlog`,
  deleteBlogKey: `${getBaseUrl(APIService.configPanel)}/deleteUserBlog`,
  createCategory: `${getBaseUrl(APIService.configPanel)}/createCategory`,
  getCategories: `${getBaseUrl(APIService.configPanel)}/getCategories`,
  updateCategory: `${getBaseUrl(APIService.configPanel)}/updateCategory`,
  deleteCategory: `${getBaseUrl(APIService.configPanel)}/deleteCategory`,
  fetchBlogs: `${getBaseUrl(APIService.configPanel)}/fetchBlogs`,
  getBlogDetail: `${getBaseUrl(APIService.configPanel)}/getBlogDetail`,
  createBlog: `${getBaseUrl(APIService.configPanel)}/createBlog`,
  updateBlog: `${getBaseUrl(APIService.configPanel)}/updateBlog`,
  deleteBlog: `${getBaseUrl(APIService.configPanel)}/deleteBlog`,
};
