import { notification } from 'antd';
import { get, remove } from 'js-cookie';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../redux/reducers';
import { out } from '../../auth/redux/authReducer';
import { ACCESS_TOKEN } from '../constants';

export function fetchThunk(
  url: string,
  method: 'get' | 'post' | 'delete' | 'put' = 'get',
  auth = true,
  body?: string | FormData,
): ThunkAction<Promise<any>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const headers: {
      'Content-Type'?: string;
      Authorization?: string;
      'Accept-Language': string;
    } = {
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      Authorization: `bearer ${get(ACCESS_TOKEN)}`,
    };

    if (body instanceof FormData) {
      delete headers['Content-Type'];
    }

    if (!auth) {
      delete headers.Authorization;
    }

    const res = await fetch(url, {
      method,
      body,
      headers,
      cache: 'no-store',
    });

    if (res.status === 401) {
      dispatch(out());
      remove(ACCESS_TOKEN);
    }

    const json = await res.json();

    if (res.status !== 200) {
      notification.error({
        message: 'Lỗi',
        description: json.error,
      });
    }

    return json;
    // throw new Error('Error');
  };
}
