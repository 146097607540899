import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import accountReducer, {
  AccountState,
} from '../modules/account/redux/accountReducer';
import authReducer, { AuthState } from '../modules/auth/redux/authReducer';
import webConfigReducer, {
  WebConfigState,
} from '../modules/webConfig/redux/webConfigReducer';

const persistConfig: PersistConfig<any> = {
  storage,
  key: 'root',
  transforms: [],
  blacklist: ['router', 'intl'],
};

export interface AppState {
  router: RouterState;
  auth: AuthState;
  account: AccountState;
  webConfig: WebConfigState;
  // intl: IntlState;
}

export default (history: History) => persistReducer(
  persistConfig,
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    account: accountReducer,
    webConfig: webConfigReducer,
  }),
);
