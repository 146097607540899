import React from 'react';
import { IntlProvider } from 'react-intl';
import viMessage from '../vi.json';

interface Props {}

const ConnectedIntlProvider: React.FunctionComponent<Props> = props => {
  return <IntlProvider locale="vi" messages={viMessage} {...props} />;
};

export default ConnectedIntlProvider;
