import { set } from 'js-cookie';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../configs/api';
import { AppState } from '../../../redux/reducers';
import { fetchThunk } from '../../common/redux/thunk';
import { ACCESS_TOKEN } from '../../common/constants';

export interface AuthState {
  auth: boolean;
}

export const in_ = createAction('auth/in')();
export const out = createAction('auth/out')();

export function login(
  params: any,
): ThunkAction<Promise<any>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const json = await dispatch(
      fetchThunk(API_PATHS.login, 'post', false, JSON.stringify(params)),
    );

    if (json?.error) {
      return json;
    }

    await set(ACCESS_TOKEN, json.access_token);
    dispatch(in_());
    return json;
  };
}

const actions = {
  in_,
  out,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: AuthState = {
    auth: false,
  },
  action: ActionT,
): AuthState {
  switch (action.type) {
    case getType(in_):
      return { ...state, auth: true };
    case getType(out):
      return { ...state, auth: false };
    default:
      return state;
  }
}
