import { message } from 'antd';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../configs/api';
import { AppState } from '../../../redux/reducers';
import { LicenseDetail, WebConfig } from '../../common/models';
import { fetchThunk } from '../../common/redux/thunk';

export interface AccountState {
  profile?: any;
  subscriptions?: any[];
  licenses: LicenseDetail[];
  licenseDetail?: LicenseDetail;
  webConfig?: WebConfig;
}

export const setProfile = createAction('account/setProfile', (data: any) => ({
  data,
}))();

export const setSubscriptions = createAction(
  'account/setSubscriptions',
  (data: any[]) => ({ data }),
)();

export const setLicenses = createAction(
  'account/setLicenses',
  (data: LicenseDetail[]) => ({ data }),
)();

export const setLicenseDetail = createAction(
  'account/setLicenseDetail',
  (data: LicenseDetail) => ({ data }),
)();

export const setWebConfig = createAction(
  'account/setWebConfig',
  (data: WebConfig) => ({ data }),
)();

export function fetchProfile(): ThunkAction<
  Promise<any>,
  AppState,
  null,
  Action<string>
  > {
  return async (dispatch, getState) => {
    const profile = await dispatch(fetchThunk(API_PATHS.profile, 'get', true));
    dispatch(setProfile(profile));
    return profile;
  };
}

export function fetchSubscriptions(): ThunkAction<
  Promise<any>,
  AppState,
  null,
  Action<string>
  > {
  return async (dispatch, getState) => {
    const json = await dispatch(
      fetchThunk(API_PATHS.subscription, 'get', true),
    );
    dispatch(setSubscriptions(json.data));
    return json;
  };
}

export function getLicenses(): ThunkAction<
  Promise<any>,
  AppState,
  null,
  Action<string>
  > {
  return async (dispatch, getState) => {
    const json = await dispatch(fetchThunk(API_PATHS.license, 'get', true));
    return json;
  };
}

export function getLicenseDetail(
  license: LicenseDetail,
): ThunkAction<Promise<void>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const licenseDetail = dispatch(
      fetchThunk(`${API_PATHS.license}/${license.id}`, 'get', true),
    );
    const webConfig = dispatch(
      fetchThunk(
        API_PATHS.getWebConfig,
        'post',
        true,
        JSON.stringify({ secretId: license.secret_id }),
      ),
    );

    const licenseDetailJson = await licenseDetail;
    const webConfigJson = await webConfig;

    message.success(
      `Chọn license ${licenseDetailJson?.data?.description} thành công`,
    );

    dispatch(setLicenseDetail(licenseDetailJson.data));
    dispatch(setWebConfig(webConfigJson.data));
  };
}

const actions = {
  setProfile,
  setSubscriptions,
  setLicenses,
  setLicenseDetail,
  setWebConfig,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: AccountState = {
    licenses: [],
  },
  action: ActionT,
): AccountState {
  switch (action.type) {
    case getType(setProfile):
      return { ...state, profile: action.payload.data };
    case getType(setWebConfig):
      return { ...state, webConfig: action.payload.data };
    case getType(setLicenses):
      return { ...state, licenses: action.payload.data };
    case getType(setLicenseDetail):
      return { ...state, licenseDetail: action.payload.data };
    default:
      return state;
  }
}
