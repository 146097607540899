import { ServiceType } from '../constants';

export const cPanelPath = '/cpanel';

function buildRoutePath(moduleName: ServiceType, path: string) {
  return `/${moduleName}${path}`;
}

export const ROUTES = {
  login: '/auth/login',
  account: `${cPanelPath}/account`,
  new: `${cPanelPath}/new`,
  license: `${cPanelPath}/license`,

  webConfig: {
    general: buildRoutePath('webConfig', '/general'),
    character: buildRoutePath('webConfig', '/character'),
    questDaily: buildRoutePath('webConfig', '/questDaily'),
    giftCode: buildRoutePath('webConfig', '/giftCode'),
    reward: buildRoutePath('webConfig', '/reward'),
    accumulation: buildRoutePath('webConfig', '/accumulation'),
    topDaily: buildRoutePath('webConfig', '/topDaily'),
    payment: buildRoutePath('webConfig', '/payment'),
    wheelSpin: buildRoutePath('webConfig', '/wheelSpin'),
    eventInGame: buildRoutePath('webConfig', '/eventInGame'),
    switchServer: buildRoutePath('webConfig', '/switchServer'),
    logPostGame: buildRoutePath('webConfig', '/logPostGame'),
    goblinChaos: buildRoutePath('webConfig', '/goblinChaos'),
  },

  blogs: {
    userBlogs: buildRoutePath('blog', '/userBlogs'),
    managerBlog: buildRoutePath('blog', '/managerBlog'),
    createBlog: buildRoutePath('blog', '/createBlog'),
  },
};

interface MENU {
  path: string;
  icon: string;
  title: string;
  subMenu?: any[];
}

export const SIDEBAR_MENU: MENU[] = [
  {
    path: ROUTES.account,
    icon: 'pie-chart',
    title: 'accountInfo',
  },
  // {
  //   path: ROUTES.new,
  //   icon: 'twitter',
  //   title: 'newNotice',
  // },
  {
    path: ROUTES.license,
    icon: 'desktop',
    title: 'license',
  },
  {
    path: ROUTES.blogs.userBlogs,
    icon: 'notification',
    title: 'blog.managerBlog',
    subMenu: [
      {
        path: ROUTES.blogs.userBlogs,
        icon: 'notification',
        title: 'blog.userBlogs',
      },
      {
        path: ROUTES.blogs.managerBlog,
        icon: 'notification',
        title: 'blog.managerBlog',
      },
    ],
  },
  {
    path: ROUTES.webConfig.character,
    icon: 'setting',
    title: 'webConfig',
    subMenu: [
      {
        path: ROUTES.webConfig.general,
        icon: 'notification',
        title: 'webConfig',
      },
      {
        path: ROUTES.webConfig.character,
        icon: 'notification',
        title: 'character',
      },
      {
        path: ROUTES.webConfig.payment,
        icon: 'notification',
        title: 'payment',
      },
      {
        path: ROUTES.webConfig.eventInGame,
        icon: 'notification',
        title: 'eventInGame',
      },
      {
        path: ROUTES.webConfig.logPostGame,
        icon: 'notification',
        title: 'logPostGame',
      },
      {
        path: ROUTES.webConfig.switchServer,
        icon: 'notification',
        title: 'switchServer',
      },
    ],
  },
  {
    path: ROUTES.webConfig.questDaily,
    icon: 'gift',
    title: 'webConfig.eventConfig',
    subMenu: [
      {
        path: ROUTES.webConfig.questDaily,
        icon: 'notification',
        title: 'questDaily',
      },
      {
        path: ROUTES.webConfig.giftCode,
        icon: 'notification',
        title: 'giftCode',
      },
      {
        path: ROUTES.webConfig.accumulation,
        icon: 'notification',
        title: 'accumulationConfig',
      },
      {
        path: ROUTES.webConfig.topDaily,
        icon: 'notification',
        title: 'topDaily',
      },
      {
        path: ROUTES.webConfig.reward,
        icon: 'notification',
        title: 'rewardConfig',
      },
      {
        path: ROUTES.webConfig.wheelSpin,
        icon: 'notification',
        title: 'wheelSpinConfig',
      },
      {
        path: ROUTES.webConfig.goblinChaos,
        icon: 'notification',
        title: 'goblinChaosConfig',
      },
    ],
  },
];
