import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import './App.css';
import ProtectedRoute from './modules/common/components/ProtectedRoute';
import { AppState } from './redux/reducers';

const LoadableAuthLayouts = lazy(() => import('./layouts/pages/AuthLayouts'));
const LoadableBasicLayouts = lazy(() => import('./layouts/pages/BasicLayouts'));

function mapStateToProps(state: AppState) {
  return { auth: state.auth };
}

interface Props extends ReturnType<typeof mapStateToProps> {}

const App: React.FC<Props> = props => {
  const { auth } = props;
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{intl.formatMessage({ id: 'webTitle' })}</title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'webDescription' })}
        />
      </Helmet>

      <Suspense fallback={null}>
        <Switch>
          <Route path="/auth" component={LoadableAuthLayouts} />
          <ProtectedRoute auth={auth.auth} component={LoadableBasicLayouts} />
        </Switch>
      </Suspense>
    </>
  );
};

export default connect(mapStateToProps)(App);
