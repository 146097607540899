import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../configs/api';
import { AppState } from '../../../redux/reducers';
import { Reward } from '../../common/models';
import { fetchThunk } from '../../common/redux/thunk';

export interface WebConfigState {
  rewards: Reward[];
}

export const setRewards = createAction(
  'webConfig/setRewards',
  (data: Reward[]) => ({
    data,
  }),
)();

export function getRewards(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  Action<string>
  > {
  return async (dispatch, getState) => {
    const state = getState();
    const { account } = state;

    const json = await dispatch(
      fetchThunk(
        API_PATHS.fetchReward,
        'post',
        true,
        JSON.stringify({
          secretId: account.licenseDetail?.secret_id,
        }),
      ),
    );

    if (json?.data) {
      dispatch(setRewards(json.data));
    }
  };
}

export function getGiftCodes(
  secretId?: string,
): ThunkAction<Promise<any>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const { webConfig } = getState();

    if (!webConfig.rewards.length) {
      dispatch(getRewards());
    }

    const json = await dispatch(
      fetchThunk(
        API_PATHS.fetchGiftCode,
        'post',
        true,
        JSON.stringify({
          secretId,
        }),
      ),
    );
    return json;
  };
}

export function getQuestDaily(
  secretId?: string,
): ThunkAction<Promise<any>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const { webConfig } = getState();

    if (!webConfig.rewards.length) {
      dispatch(getRewards());
    }

    const json = await dispatch(
      fetchThunk(
        API_PATHS.fetchQuestDaily,
        'post',
        true,
        JSON.stringify({
          secretId,
        }),
      ),
    );
    return json;
  };
}

export function getAccumulations(
  secretId?: string,
): ThunkAction<Promise<any>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const { webConfig } = getState();

    if (!webConfig.rewards.length) {
      dispatch(getRewards());
    }

    const json = await dispatch(
      fetchThunk(
        API_PATHS.fetchAccumulation,
        'post',
        true,
        JSON.stringify({
          secretId,
        }),
      ),
    );
    return json;
  };
}
export function getTopDaily(
  secretId?: string,
): ThunkAction<Promise<any>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const { webConfig } = getState();

    if (!webConfig.rewards.length) {
      dispatch(getRewards());
    }

    const json = await dispatch(
      fetchThunk(
        API_PATHS.fetchTopDaily,
        'post',
        true,
        JSON.stringify({
          secretId,
        }),
      ),
    );
    return json;
  };
}

const actions = {
  setRewards,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: WebConfigState = {
    rewards: [],
  },
  action: ActionT,
): WebConfigState {
  switch (action.type) {
    case getType(setRewards):
      return { ...state, rewards: action.payload.data };
    default:
      return state;
  }
}
